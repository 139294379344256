<script>
export default {
  metaInfo: {
    title: "Currently Running Tests",
  }
};
</script>

<script setup>
import RunningTestsView from '@/components/dynamic/RunningTestsView.vue';
</script>

<template>
  <RunningTestsView/>
</template>

<style scoped>

</style>
